a,
a:hover,
.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-header,
.dropdown-item.active,
.dropdown-item:active,
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active,
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-link:focus,
.nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link,
.page-item.active .page-link,
.page-link:hover,
.page-link:focus,
.bg-dark.text-inverse a:not(.btn):not([class*=link-]):hover,
.text-inverse a:not(.btn):hover,
[class*=offcanvas-].text-inverse a:not(.btn):not([class*=link-]):hover,
.filter:not(.basic-filter) ul li a.active,
.filter:not(.basic-filter) ul li a:hover,
.filter.basic-filter ul li a.active,
.filter.basic-filter ul li a:hover,
.icon-list.bullet-primary i,
.icon-list.bullet-soft-primary i,
.link-primary,
.unordered-list.bullet-primary li:before,
.link-primary:focus,
.link-primary:hover,
.post-meta li a:hover,
.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-soft-primary:hover,
.btn-white.btn-play:hover,
.btn-soft-primary,
.accordion-wrapper .card-header button,
.accordion-wrapper .card-header button:before,
.accordion-wrapper .card-header button:hover,
.collapse-link,
.collapse-link:hover,
.breadcrumb-item a:hover,
.plyr--full-ui input[type=range],
.progress-wrap:after,
.navbar-light.fixed .nav-link:focus,
.navbar-light.fixed .nav-link:hover,
.external:hover,
.external:hover:after,
.navbar-expand.navbar-light .navbar-nav .dropdown:not(.dropdown-submenu)>.dropdown-toggle:after,
.navbar-light .language-select .dropdown-toggle:after {
  color: #E03F3F;
}
.link-dark:hover,
.text-primary,
.text-reset a:hover,
a.text-reset:hover,
.list-unstyled li a.active,
.icon-list.bullet-bg i[class*=bullet-soft-primary],
.icon-list.bullet-bg i.bullet-white,
.icon-list.bullet-white i,
.sidebar nav .nav-link.active,
.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
  color: #E03F3F !important;
}
.form-control:focus {
  border-color: #f1b1b5;
}
.text-line.text-primary:before,
.tooltip-inner,
.icon-list.bullet-primary.bullet-bg i,
.form-check-input:checked,
.btn-primary,
.btn-primary:hover,
.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle,
.btn-primary.disabled:not(.btn-circle):not(.btn-block),
.btn-primary:disabled:not(.btn-circle):not(.btn-block),
.btn-outline-primary:hover,
.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active,
.navbar.navbar-light.fixed .btn,
.text-line:before {
  background-color: #E03F3F;
}
.bg-primary,
.icon-list.bullet-bg i[class*=bullet-primary] {
  background-color: #E03F3F !important;
}
.btn-primary.btn-circle.ripple::after,
.btn-primary.btn-circle.ripple::before,
.plyr--video .plyr__controls .plyr__control.plyr__tab-focus,
.plyr--video .plyr__controls .plyr__control:hover,
.plyr--video .plyr__controls .plyr__control[aria-expanded=true] {
  background: #E03F3F;
}
.swiper-container.nav-dark .swiper-slide figure .item-link:hover,
.swiper-container.nav-dark .swiper-navigation .swiper-button:hover {
  background: #E03F3F !important;
}
.text-inverse a:not(.btn):hover,
.form-check-input:checked,
.btn-primary,
.btn-primary:hover,
.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-primary.disabled:not(.btn-circle):not(.btn-block),
.btn-primary:disabled:not(.btn-circle):not(.btn-block),
.btn-outline-primary,
.btn-outline-primary:hover,
.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active,
.navbar.navbar-light.fixed .btn,
blockquote,
.post-meta li a:hover,
.hero-slider:before,
.lg-outer .lg-item:after,
.loader,
.tp-loader.spinner {
  border-color: #E03F3F;
}
.border-primary {
  border-color: #E03F3F !important;
}
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #E03F3F;
}
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before,
.wrapper.bg-primary.angled.lower-end:after,
.wrapper.bg-primary.angled.upper-end:before {
  border-right-color: #E03F3F;
}
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #E03F3F;
}
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before,
.wrapper.bg-primary.angled.lower-start:after,
.wrapper.bg-primary.angled.upper-start:before {
  border-left-color: #E03F3F;
}
.progress-list .progressbar.primary svg path:last-child,
.progress-wrap svg.progress-circle path {
  stroke: #E03F3F;
}
.progress-list .progressbar.soft-primary svg path:last-child {
  stroke: #eea1a6;
}
.lineal-fill,
.text-primary .lineal-fill {
  fill: #eea1a6;
}
.primary-fill,
.text-primary .primary-fill {
  fill: #E03F3F;
}
.shape .svg-fill {
  fill: #E03F3F;
}
.shape.soft-primary .svg-fill {
  fill: #fdf3f4;
}
.shape.pale-primary .svg-fill {
  fill: #fae6e7;
}
::-moz-selection {
  background: rgba(226, 98, 107, 0.7);
}
::selection {
  background: rgba(226, 98, 107, 0.7);
}
.underline.primary:after,
.underline:after {
  background: rgba(226, 98, 107, 0.12);
}
.underline-2.primary:after,
.underline-2:not([class*=underline-gradient-]):after {
  background: #E03F3F;
}
.underline-3.primary.style-1:before,
.underline-3.primary.style-1:after {
  border-color: #E03F3F;
}
.underline-3.primary.style-2:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 208.01 8.3'%3E%3Cpath fill='%23E03F3F' d='M64.42,2.42Q42.78,1.46,21.19,0c-2.8-.19-4.09.89-3.87,2L3.92,1.87c-5.13-.05-5.28,3.87-.12,3.92l60.49.55c46.63,2.08,93.34,2.51,139.81,1.27,5-.13,5.39-3.87.13-3.92Z'/%3E%3C/svg%3E");
}
.underline-3.primary.style-3:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 205 9.37'%3E%3Cpath fill='%23E03F3F' d='M202.47,9.37A1191.26,1191.26,0,0,0,1.79,7.48,1.67,1.67,0,0,1,0,5.92H0A1.76,1.76,0,0,1,1.63,4.21c67-5.71,133.83-5.43,200.8-.27A2.75,2.75,0,0,1,205,6.88h0A2.6,2.6,0,0,1,202.47,9.37Z'/%3E%3C/svg%3E");
}
a.hover-2:before,
a.hover-3:before {
  background-color: #fae6e7;
}
.bg-soft-primary {
  background-color: #fdf3f4 !important;
}
.text-soft-primary {
  color: #fdf3f4 !important;
}
.bg-pale-primary {
  background-color: #fae6e7 !important;
}
.text-pale-primary {
  color: #fae6e7 !important;
}
.bg-gradient-primary {
  background: linear-gradient(180deg, #fdf3f4, rgba(255, 255, 255, 0) 100%);
}
.bg-gradient-reverse-primary {
  background: linear-gradient(0deg, #fdf3f4, rgba(255, 255, 255, 0) 100%);
}
.border-soft-primary {
  border-color: #f6d0d3 !important;
}
.wrapper.bg-soft-primary.angled.lower-start:after,
.wrapper.bg-soft-primary.angled.upper-start:before {
  border-left-color: #fdf3f4;
}
.wrapper.bg-soft-primary.angled.lower-end:after,
.wrapper.bg-soft-primary.angled.upper-end:before {
  border-right-color: #fdf3f4;
}
.wrapper.bg-pale-primary.angled.lower-start:after,
.wrapper.bg-pale-primary.angled.upper-start:before {
  border-left-color: #fae6e7;
}
.wrapper.bg-pale-primary.angled.lower-end:after,
.wrapper.bg-pale-primary.angled.upper-end:before {
  border-right-color: #fae6e7;
}
.icon-list.bullet-soft-primary.bullet-bg i {
  background-color: #fae3e4;
}
.icon-list.bullet-bg i[class*=bullet-soft-primary] {
  background-color: #fae3e4 !important;
}
form.dark-fields .form-control:focus {
  border-color: rgba(226, 98, 107, 0.5);
}
.btn-soft-primary {
  background-color: #fae6e7;
}
.btn-expand.btn-primary {
  color: #E03F3F;
}
.btn-expand.btn-primary:before {
  background-color: #E03F3F;
}
.btn-expand.btn-soft-primary {
  color: #E03F3F;
}
.btn-expand.btn-soft-primary i {
  color: #E03F3F;
}
.btn-expand.btn-soft-primary:before {
  color: #E03F3F;
  background-color: #fae6e7;
}
.btn-expand.btn-soft-primary:hover {
  color: #E03F3F;
}
.btn-expand.btn-soft-primary:hover:before {
  color: #E03F3F;
}
.alert-dismissible.alert-primary .btn-close {
  color: #cb5860;
}
.alert-dismissible.alert-primary .btn-close:hover {
  color: #883b40;
}
.alert-primary {
  color: #9e454b;
  background-color: #fceff0;
  border-color: #f6d0d3;
}
.alert-primary .alert-link {
  color: #b54e56;
}
.swiper-container.nav-dark .swiper-slide figure .item-link,
.swiper-container.nav-dark .swiper-navigation .swiper-button {
  background: rgba(226, 98, 107, 0.9) !important;
}
.swiper-container.nav-dark .swiper-navigation .swiper-button-disabled {
  background: rgba(226, 98, 107, 0.7) !important;
}
.overlay.color span.bg {
  background: rgba(226, 98, 107, 0.7);
}
.itooltip-color.itooltip-inner,
.itooltip-primary.itooltip-inner {
  background: rgba(226, 98, 107, 0.9);
}
.btn-soft-primary.btn-circle.ripple::after,
.btn-soft-primary.btn-circle.ripple::before {
  background-color: #fdf3f4;
}
.bg-dot.primary {
  background-image: radial-gradient(#E03F3F 2px, transparent 2.5px);
}
.bg-line.primary {
  background: repeating-linear-gradient(-55deg, rgba(255, 255, 255, 0) 0.8px, #E03F3F 1.6px, #E03F3F 3px, rgba(255, 255, 255, 0) 3.8px, rgba(255, 255, 255, 0) 10px);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='45' height='32' viewBox='0 0 45 32'><path fill='#E03F3F' d='M26.88 29.888c-1.076 1.289-2.683 2.103-4.48 2.103s-3.404-0.814-4.472-2.093l-0.008-0.009-5.12-7.040-8.192-10.048-3.52-4.608c-0.646-0.848-1.036-1.922-1.036-3.087 0-2.828 2.292-5.12 5.12-5.12 0.139 0 0.277 0.006 0.413 0.016l-0.018-0.001h33.664c0.118-0.010 0.256-0.015 0.396-0.015 2.828 0 5.12 2.292 5.12 5.12 0 1.165-0.389 2.239-1.045 3.1l0.009-0.013-3.52 4.608-7.872 10.048z'/></svg>");
}
.cursor-primary a {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23E03F3F;opacity:0.9;%7D.cls-2%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ccircle class='cls-1' cx='25' cy='25' r='25'/%3E%3Cpath class='cls-2' d='M21.79,26.79,17,31.59V30a1,1,0,0,0-2,0v4a1,1,0,0,0,.08.38,1,1,0,0,0,.54.54A1,1,0,0,0,16,35h4a1,1,0,0,0,0-2H18.41l4.8-4.79a1,1,0,0,0-1.42-1.42Z'/%3E%3Cpath class='cls-2' d='M34.92,15.62a1,1,0,0,0-.54-.54A1,1,0,0,0,34,15H30a1,1,0,0,0,0,2h1.59l-4.8,4.79a1,1,0,0,0,0,1.41h0a1,1,0,0,0,1.41,0h0L33,18.41V20a1,1,0,0,0,2,0V16A1,1,0,0,0,34.92,15.62Z'/%3E%3C/svg%3E"), auto;
}
.process-wrapper.arrow [class*=col-]:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 108.15 19.5'%3E%3Cpath fill='%23E03F3F' d='M2.38,13.63A107.77,107.77,0,0,1,41.56,3.28C54.29,2.51,67,4.2,79.4,6.9c7.73,1.68,15.38,3.73,23,5.86a1.56,1.56,0,0,0,.83-3C89.85,6,76.35,2.5,62.54.87A111,111,0,0,0,24,2.73,119,119,0,0,0,.8,10.93c-1.81.85-.23,3.54,1.58,2.7Z'/%3E%3Cpath fill='%23E03F3F' d='M95.51,19.27A60.35,60.35,0,0,1,107,12.41a1.58,1.58,0,0,0,1.12-1.29,1.53,1.53,0,0,0-.75-1.56,20.31,20.31,0,0,1-9-8.65,1.56,1.56,0,0,0-2.7,1.57c2.34,4,5.79,7.86,10.12,9.78l.37-2.86a62.69,62.69,0,0,0-12.25,7.18,1.6,1.6,0,0,0-.56,2.14,1.56,1.56,0,0,0,2.13.55Z'/%3E%3C/svg%3E");
}
@media (min-width: 576px) {
  .navbar-expand-sm.navbar-light .navbar-nav .dropdown:not(.dropdown-submenu)>.dropdown-toggle:after {
    color: #E03F3F;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md.navbar-light .navbar-nav .dropdown:not(.dropdown-submenu)>.dropdown-toggle:after {
    color: #E03F3F;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg.navbar-light .navbar-nav .dropdown:not(.dropdown-submenu)>.dropdown-toggle:after {
    color: #E03F3F;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl.navbar-light .navbar-nav .dropdown:not(.dropdown-submenu)>.dropdown-toggle:after {
    color: #E03F3F;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl.navbar-light .navbar-nav .dropdown:not(.dropdown-submenu)>.dropdown-toggle:after {
    color: #E03F3F;
  }
}