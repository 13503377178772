body {
    overflow-y: auto !important;
    padding: 0 !important;
}

.invalid-feedback {
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.7rem;
    color: #dc3545;
}


.ar {
    direction: rtl !important;
    text-align: right !important;
}

.en {
    direction: ltr !important;
    text-align: left !important;
}

.icon {
    -webkit-margin-end: .5rem;
}

.en .form-floating>label {
    left: 0;
}

.ar .form-floating>label {
    right: 0;
}

.en .icon-list i {
    left: 0;
}

.ar .icon-list i {
    right: 0;
}

.icon-list,
.list-unstyled {
    padding: 0 !important;
}

.back-to-top {
    position: fixed;
    color: #e2626b;
    right: 1.5rem;
    bottom: 1.5rem;
    width: 2.3rem;
    height: 2.3rem;
    cursor: pointer;
    display: block;
    border-radius: 100%;
    box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
    z-index: 1010;
    transform: translateY(0.75rem);
    transition: all 200ms linear, margin-right 0ms;
    background-color: transparent;
    border: none;
    font-size: 25px;
}

.back-to-top:after {
    font-family: "Unicons";
    content: "\e951";
}

.header2 {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
    height: 130px !important;
    background-color: #fff !important;
    color: rosybrown !important;
    transform: translateY(10) !important;
    transition: transform 6s ease !important;
    animation: myanimation 3s !important;
    z-index: 20202020 !important;
    height: 75px !important;
    color: #000 !important;
}

.header-home button.hamburger {
    color: #FFF;
}

.header-home2 button.hamburger {
    color: #000;
}

.header2 .topbar {
    display: none !important;
}

@media (max-width: 992px) {
    .header2 .topbar {
        display: flex !important;
    }
}

@media (min-width: 992px) {
    .d-header {
        display: none !important;
    }

    .header2 .d-header {
        display: block !important;
    }
}

.header-home {
    position: absolute !important;
}

.header-home2 {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
    height: 130px !important;
    background-color: #fff !important;
    color: rosybrown !important;
    transform: translateY(10) !important;
    transition: transform 6s ease !important;
    animation: myanimation 3s !important;
    z-index: 20202020 !important;
    height: 75px !important;
}

.header-home2 .nav-link {
    color: #343f52 !important;
}

.header-home2 .nav-item.dropdown.language-select.text-uppercase {
    color: #343f52 !important;
}

.header-home2 .logo-dark {
    display: block !important;
}

.header-home2 .logo-light {
    display: none !important;
}

.header2 .logo-dark {
    display: block !important;
}

.header2 .logo-light {
    display: none !important;
}

.form-switch .form-check-input {
    width: 2.7rem !important;
    height: 1.5rem !important;
}

.form-check-label {
    top: -12px !important;
    left: 8px !important;
}

.form-check-label.ar {
    right: 13px !important;
}

.en .navbar .navbar-other .navbar-nav>.nav-item+.nav-item {
    margin-left: 0.8rem !important;
    margin-right: 0rem !important;
}

.ar .navbar .navbar-other .navbar-nav>.nav-item+.nav-item {
    margin-right: 0.8rem !important;
    margin-left: 0rem !important;
}

.en .ms {
    margin-left: 1rem;
}

.ar .ms {
    margin-right: 1rem;
}



@media (min-width: 1400px) {
    .en .pe-xxl {
        padding-right: 8rem !important;
    }

    .ar .pe-xxl {
        padding-left: 8rem !important;
    }

    .en .pe-xxl-2-5 {
        padding-right: 22.5rem !important;
    }

    .ar .pe-xxl-2-5 {
        padding-left: 22.5rem !important;
    }

}

@media (min-width: 1200px) {
    .en .pe-xl-2-2 {
        padding-right: 15rem !important;
    }

    .ar .pe-xl-2-2 {
        padding-left: 15rem !important;
    }
}

@media (min-width: 992px) {
    .en .pe-lg-2-0 {
        padding-right: 10rem !important;
    }

    .ar .pe-lg-2-0 {
        padding-left: 10rem !important;
    }
}

.en .me {
    margin-right: .5rem !important;
}

.ar .me {
    margin-left: .5rem !important;
}

.en .icon-list.bullet-bg li {
    padding-left: 1.5rem;
}

.ar .icon-list.bullet-bg li {
    padding-right: 1.5rem;
}

.en .me-4 {
    margin-right: 1rem !important;
}

.ar .me-4 {
    margin-left: 1rem !important;
}

.form-switch .form-check-input {
    background-color: #eeeeee !important;
    border: 1px solid #eee !important;
}

.blok:nth-of-type(odd) {
    background-color: white;
}

.blok:nth-of-type(even) {
    background-color: black;
}

@-webkit-keyframes border-transform {

    0%,
    100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }

    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }

    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }

    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }

    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }

    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }

    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}

.btn-close {
    /* box-sizing: content-box; */
    padding: 0.25em 0.25em;
    border: 0 !important;
    border-radius: 0 !important;
    opacity: 1 !important;
    margin-right: 0.7rem !important;
}

.btn-close-white {
    filter: none !important;
}

.price-value {
    transition: ease 3s ease-in-out !important;
}

.nav-item.dropdown.language-select.text-uppercase {
    background-color: transparent;
    color: #fff;
    border: none;
    font-weight: bold;
    padding: .5rem;
    transition: box-shadow 300ms ease-out;
}

.nav-item.dropdown.language-select.text-uppercase .nav-item {
    color: #000;
    margin-bottom: 1rem !important;
    font-weight: bold;
}

select:focus {
    border: none !important;
    box-shadow: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
    display: none;
}

select:focus {
    box-shadow: none !important;
    outline: none !important;
}

.pricing-wrapper [class*="col-"] .pricing.card .prices .price-duration:before {
    content: "" !important;
    padding-right: 0.25rem;
    padding-left: 0.1rem;
}


.messages {
    position: relative;
    display: flex;
    flex-direction: column;
}

.messages .message {
    margin: 0;
    /* Reset margin for all (p) elements */
    height: 15px;
    font-size: 11px;
    display: block;
}

.messages.en .message {
    text-align: left;
    margin-left: 15px;
}

.messages.ar .message {
    text-align: right;
    margin-right: 15px;
}

.message.explanation {
    color: var(--text-field-label-color);
}

.messages .message.error {
    color: red;
}

.en .form-floating>label {
    position: absolute;
    top: 0;
    left: 8px !important;
    right: 0px;
    height: 100%;
    padding: 0.6rem 1rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.ar .form-floating>label {
    position: absolute;
    top: 0;
    right: 8px !important;
    height: 100%;
    padding: 0.6rem 1rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}